<template>
  <label
    class="FileUploader m-b-m"
  >
    <div
      tabindex="0"
      class="el-upload el-upload--text"
      @drop.prevent="onFileAdded($event.dataTransfer.files[0])"
      @dragover.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
    >
      <div
        v-loading="isLoading"
        :class="{ 'is-dragover': dragover }"
        class="el-upload-dragger"
      >
        <i class="el-icon-upload" />
        <div class="el-upload__text" v-html="$t('forms.drop_files_here')" />
      </div>

      <input
        type="file"
        name="file"
        accept="audio/*, application/pdf, image/jpeg, image/png"
        class="el-upload__input"
        @change.prevent="onFileAdded($event.target.files[0])"
      >
    </div>
  </label>
</template>

<script>
export default {
  props: {
    upload: {
      type: Function,
      required: true
    },
    maxFileSize: {
      type: Number,
      default: 25
    }
  },

  data () {
    return {
      dragover: false,
      isLoading: false
    }
  },

  methods: {
    onFileAdded (file) {
      if (!file) return

      const fileSize = this.getFileSizeMb(file)

      if (fileSize > this.maxFileSize) {
        this.$notify.error(this.$t('errors.max_upload_file_size', { size: fileSize, max: this.maxFileSize }))

        return
      }

      this.dragover = false
      this.uploadFile(file)
    },

    getFileSizeMb (file) {
      return Math.round(file.size / 1024 / 1024 * 100) / 100
    },

    uploadFile (file) {
      this.isLoading = true
      this.upload(file)
        .catch((error) => {
          this.$displayRequestError(error, this.$t('errors.files_cannot_be_uploaded'))
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.FileUploader {
  .el-upload {
    margin: auto;
    display: block;

    .el-upload-dragger {
      width: 100%;
      display: inline-block;
    }
  }
}
</style>
